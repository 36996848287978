<template>
  <v-navigation-drawer
    :value="isAddNewPromotionSidebarActive"
    :permanent="isAddNewPromotionSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="'450px'"
    app
    @input="val => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Promotion</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-item-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                v-model="planFilter1"
                label="Linked with"
                placeholder="Linked with"
                :items="planOptions1"
                item-text="title"
                item-value="value"
                class="mb-6"
                :rules="[validators.required]"
                outlined
                dense
                clearable
                hide-details="auto"
              ></v-select>

              <v-text-field
                v-if="planFilter1 == 11"
                v-model="itemData.link"
                outlined
                dense
                :rules="[validators.required, validators.urlValidator]"
                label="Link"
                placeholder="Link"
                hide-details="auto"
                class="mb-6"
                type="text"
              ></v-text-field>

              <v-select
                v-if="planFilter1 == 12"
                v-model="itemData.type_id"
                label="From"
                :rules="[validators.required]"
                placeholder="From"
                :items="planOptions"
                item-text="title"
                item-value="value"
                class="mb-6"
                outlined
                dense
                clearable
                hide-details="auto"
              ></v-select>

              <v-card-text v-if="errorMsg.type_id" style="color: #ff3342; white-space: pre-wrap">
                {{ errorMsg.type_id[0] }}
              </v-card-text>

              <v-text-field v-if="itemData.type_id != 5 && itemData.type_id"
                v-model="itemData.item_id"
                :rules="[validators.required, validators.integerValidator]"
                outlined
                dense
                :label="
                  itemData.type_id == 1
                  ? 'Chief ID'
                  : itemData.type_id == 2
                  ? 'Category ID'
                  : itemData.type_id == 3
                  ? 'Item ID'
                  : itemData.type_id == 4
                  ? 'Event ID'
                  : ''"
                :placeholder="
                  itemData.type_id == 1
                  ? 'Chief ID'
                  : itemData.type_id == 2
                  ? 'Category ID'
                  : itemData.type_id == 3
                  ? 'Item ID'
                  : itemData.type_id == 4
                  ? 'Event ID'
                  : ''"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>

              <v-text-field
                v-model="itemData.priority"
                outlined
                :rules="[validators.required, validators.integerValidator]"
                @keypress="isNumber($event)"
                maxLength="1"
                dense
                label="Priority"
                placeholder="Priority"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
              <v-card-text v-if="errorMsg.priority" style="color: #ff3342; white-space: pre-wrap">
                {{ errorMsg.priority[0] }}
              </v-card-text>
              
              <v-file-input
              v-model="itemData.image"
              accept=".jpg, .jpeg, .png"
              placeholder="Choose an image"
              outlined
              type="file"
              dense
              label="Upload Image"
              class="mb-6"
              :prepend-icon="icons.mdiCamera"
              hint="Image dimensions: width: 800px, height: 267px,  max-file-size: 512kb"
                :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], itemData.image), errorImg()]"
                :persistent-hint="errorMsg.image ? false : true"
                >
              </v-file-input>

              <v-card-text v-if="message" style="color: #ff3342; white-space: pre-wrap">
                {{ message }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetItemData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { fileRequired, imageValidator, integerValidator, required, urlValidator } from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { isString } from '@vueuse/shared'

export default {
  model: {
    prop: 'isAddNewPromotionSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewPromotionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blankItemData = {
      type_id: null,
      priority: null,
      link: null,
      item_id: null,
      image: [],
    }
    const planOptions1 = [
      { title: 'External', value: 11 },
      { title: 'Internal', value: 12 },
      { title: 'None', value: 13 },
    ]
    const planOptions = [
      { title: 'Chefs', value: 1 },
      { title: 'Categories', value: 2 },
      { title: 'Meals', value: 3 },
      { title: 'Events', value: 4 },
    ]
    const planFilter = ref('')
    const planFilter1 = ref('')
    const errorMsg = ref('')
    const message = ref('')
    const old_img = ref(null)
    const isError = ref(false)
    const valid = ref(false)
    const isLoading = ref(false)
    const form = ref(null)
    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }
    const errorImg = () => {
      if (errorMsg.value.image) {
        if (itemData.value.image != old_img.value) {
          errorMsg.value.image = ''
          return true
        }
        return errorMsg.value.image[0] + '\n,Image dimensions: width: 800px, height: 267px,  max-file-size: 512kb'
      } else {
        return true
      }
    }

    const resetItemData = () => {
      itemData.value = JSON.parse(
        JSON.stringify({
          type_id: null,
          priority: null,
          link: null,
          item_id: null,
          image: [],
        }),
      )
      errorMsg.value = ''
      resetForm()
      emit('update:is-add-new-item-sidebar-active', false)
    }
    const isNumber = evt => {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
    const onSubmit = () => {
      if (valid.value) {
        if (planFilter1.value == 11) {
          itemData.value.type_id = 5
          itemData.value.item_id = null
        }else if (planFilter1.value == 13){
          itemData.value.type_id = 0
          itemData.value.item_id = null
          itemData.value.link = null
        }else if (planFilter1.value == 12) {
          itemData.value.link = null
        }
        console.log('itemData.value', itemData.value)
        isLoading.value = true
        old_img.value = itemData.value.image
        store
          .dispatch('app-promotion/addPromotion', itemData.value)
          .then(response => {
            isLoading.value = false
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            resetItemData()
          })
          .catch(error => {
            isLoading.value = false
            console.log('error',error.data);
            if (error?.data?.message) {              
              if (_.isObject(error.data.message)) {
                errorMsg.value = error.data.message
              } else {
                message.value = error.data.message
              }
            }
            errorImg()
          })
      } else {
        validate()
      }
    }
    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 512000) {
          return `MAX SIZE 512KB`
        }
        if (accept.includes(data?.name?.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }
    const handleAler = () => {
      isError.value = false
    }

    const removeSize = item => {
      itemData.value.size = itemData.value.size.filter(size => size !== item)
    }

    return {
      isLoading,
      errorImg,
      isNumber,
      errorMsg,
      message,
      fileAccept,
      resetItemData,
      form,
      onSubmit,
      itemData,
      valid,
      validate,
      isError,
      handleAler,
      blankItemData,
      removeSize,
      planOptions,
      planFilter,
      planOptions1,
      planFilter1,

      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
        fileRequired,
        urlValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
