<template>
  <div id="promotion-list">
    <!-- app drawer -->
    <promotion-list-add-new v-model="isAddNewPromotionSidebarActive" @refetch-data="fetchPromotions"></promotion-list-add-new>

    <!-- promotion total card -->
    <v-row class="mb-5">
      <v-col v-for="total in promotionTotalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Promotions </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <!-- search -->
      <v-card-text v-if="promotionListTable.length<3" class="d-flex align-center flex-wrap pb-0">
        <div class="align-right ml-auto">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewPromotionSidebarActive = !isAddNewPromotionSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Promotion</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="promotionListTable"
        :options.sync="options"
        :server-items-length="totalPromotionListTable"
        :loading="loading"
        @refetch-data="fetchPromotions"
      >

        <!-- Promotion id -->
        <template #[`item.id`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">#{{ zeroP(item.id) }}</span>
          </div>
        </template>

        <!-- Image -->
        <template #[`item.image`]="{ item }">
          <div class="d-flex align-center">
            <v-img height="80" width="50" class="img-list" :src="item.image"></v-img>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-promotion-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="handleDelete(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import PromotionStoreModule from '../PromotionStoreModule'
import PromotionListAddNew from './PromotionListAddNew.vue'
import usePromotionList from './usePromotionList'

export default {
  components: {
    PromotionListAddNew,
  },
  setup(props, { emit }) {
    const PROMOTION_APP_STORE_MODULE_NAME = 'app-promotion'

    // Register module
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_APP_STORE_MODULE_NAME, PromotionStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME)
    })
    const {
      promotionListTable,
      tableColumns,
      searchQuery,
      totalPromotionListTable,
      loading,
      options,
      promotionTotalLocal,
      selectedRows,
      planFilter,
      fetchPromotions,
    } = usePromotionList()

    const isAddNewPromotionSidebarActive = ref(false)

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const zeroP = (val) => {
      return zeroPad(val,4)
    }
    const planOptions = [
      { title: 'Name (EN)', value: 'name_en' },
      { title: 'Name (AR)', value: 'name_ar' },
    ]

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this promotion?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-promotion/deletePromotion', id)
            .then(() => {
              promotionListTable.value = promotionListTable.value.filter(item => item.id !== id)
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            })
            .catch(error => {
              let textValue = null
              if (_.isObject(error.data.message)) {
                textValue = `${Object.values(error.data.message).join('\n')}`
              } else {
                textValue = error.data.message
              }
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: textValue,
              })
            })
        }
      })
    }

    return {
      zeroP,
      promotionListTable,
      tableColumns,
      searchQuery,
      totalPromotionListTable,
      loading,
      options,
      promotionTotalLocal,
      isAddNewPromotionSidebarActive,
      selectedRows,
      planOptions,
      planFilter,
      avatarText,
      fetchPromotions,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';
</style>
